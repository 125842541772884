import React from "react"
import LoadingSpinnerContent from "../../../common/components/LoadingSpinner/LoadingSpinnerContent"
import { ScreenComponentProps } from "./TaskActionerPage"
import ScreenSimpleAction from "./common/ScreenSimpleAction"
import { pageConfig_ShowProject, useRouteTo } from "../../../routes"
import { TeamType, useShowProjectQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from "../../../api/graphql"
import { Duration } from "luxon"

const ScreenInviteHomeowner: React.FC<ScreenComponentProps> = (props) => {
  const { getActionableTaskQuery } = props
  const routeToContractorProfile = useRouteTo(pageConfig_ShowProject.path)
  const gqlDataSource = useGraphQLDataSource({ api: "core" })

  const showProject = useShowProjectQuery(gqlDataSource,
    { id: getActionableTaskQuery.data?.getTask.projectId || "" },
    {
      staleTime: Duration.fromObject({ seconds: 30 }).toMillis(),
      enabled: !!getActionableTaskQuery.data?.getTask.projectId,
    },
  )

  if (!getActionableTaskQuery.data || !showProject.data) return <LoadingSpinnerContent name="ScreenInviteArchitect" />
  const task = getActionableTaskQuery.data.getTask
  const project = showProject.data.getProject

  const projectHasHomeowner = project.members.some(x => x.team.type === TeamType.Homeowner) || project.memberInvites.some(x => x.requiredTeamType === TeamType.Homeowner)
  const buttonText = projectHasHomeowner ? "Homeowner Invited" : "Invite Homeowner"

  return <>
    <ScreenSimpleAction {...props} disabled={projectHasHomeowner} onClick={routeToContractorProfile({ id: task.projectId }, "invite=HOMEOWNER")} name="ScreenInviteHomeowner" buttonText={buttonText} />
  </>
}

export default ScreenInviteHomeowner
