import React from 'react'
import { IonIcon, IonItem, IonText, useIonRouter } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import { useRouteMatch } from 'react-router-dom'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ensureTrailingSlash } from '../../../common/utils'
import TeamMembers from '../TeamMembers'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetArchitectProfileQuery } from '../../../graphql/generated'
import ProfileHeader from '../ProfileHeader'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import { MyArchitectProfileDetailsCard } from './MyArchtiectProfileDetailsCard'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { ArchitectTimelineCard } from './ArchitectTimelineCard'
import { pageConfig_Profile_Edit, useRouteTo } from '../../../routes'
{/**@deprecated -- Profile.modules.scss holds common styling among the teams which is no longer applicable */}
import DeprecatedStyles from '../Profile.module.scss' // Remove this file with MW-2481
import Styles from './MyArchitectProfile.module.scss'

const MyArchitectProfilePage: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data , error, isFetching, refetch } = useGetArchitectProfileQuery(
    gqlDataSource,
    { architectTeamId: myTeam?.id ?? '' },
    {
      staleTime: 5 * 60 * 1000,
      enabled: myTeam !== undefined,
    },
  )

  const weaverFlags = useWeaverFlags()

  const ionRouter = useIonRouter()
  const match = useRouteMatch()
  const routeToEditMyProfile = useRouteTo(pageConfig_Profile_Edit.path)
  const goToEditMyProfile = routeToEditMyProfile({})

  if (myTeam === undefined || isFetching) {
    return <LoadingSpinnerPage name="MyArchitectProfilePage" />
  }

  if (error) {
    console.error(`[MyArchitectProfilePage] Failed to load Architect Profile: `, { myTeam, data, error })
    return <ErrorBlockPage name='MyArchitectProfilePage' onRefresh={refetch} />
  }

  if (data?.getArchitectProfile == null) {
    console.warn(`[MyArchitectProfilePage] Architect Profile is missing: `, { myTeam, data, error })
    return <ErrorBlockPage name='MyArchitectProfilePage'><p>Unable to find the Architect Profile.</p></ErrorBlockPage>
  }

  const profile = data.getArchitectProfile

  return (
    <WeaverIonPage id='MyArchitectProfilePage'>
      <WeaverIonHeader>
        <ProfileHeader isMyTeam={true} />
      </WeaverIonHeader>
      <WeaverIonContent className={weaverFlags['MW-2481-architect-profile-ui-upgrade'] ? Styles.displayArchitectProfileContainer : DeprecatedStyles.deprecatedArchitectProfileContainer}>
        {weaverFlags['MW-2481-architect-profile-ui-upgrade']
          ? <>
            <MyArchitectProfileDetailsCard architectProfile={profile} goToEditMyProfile={goToEditMyProfile} />
            <ArchitectTimelineCard architectProfile={profile} />
          </>
          : <>
            <IonItem className={`${DeprecatedStyles.ionItem} ion-no-padding ${DeprecatedStyles.cursorEnabled}`} lines='none' onClick={event => {
              event.preventDefault()
              const newLocation = `${ensureTrailingSlash(match.url)}edit`
              ionRouter.push(newLocation)
            }}>
              <h3>{profile.companyTradingAs}</h3>
              <IonIcon className={DeprecatedStyles.editIcon} color='primary' src={createOutline} ></IonIcon>
            </IonItem>

            <IonText className={DeprecatedStyles.lightGrey}>
              <p> Architect </p>
            </IonText>
            <IonText className={DeprecatedStyles.lightGrey}>
              <p>{formatAddressToSingleLine(profile.companyAddress) ?? <em>No Address</em>}</p>
            </IonText>
          </>
        }

        {!weaverFlags['MW-2481-architect-profile-ui-upgrade'] && <TeamMembers teamId={myTeam.id} />}
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default MyArchitectProfilePage
