import { IonButton } from '@ionic/react'
import { Duration } from 'luxon'
import React, { ComponentProps, MouseEventHandler } from 'react'
import { useGraphQLDataSource } from '../../api/graphql'
import Styles from "./NextTaskButton.module.scss"

import { useListProjectTasksQuery } from '../../graphql/generated'
import { pageConfig_TaskActioner, useRouteTo } from '../../routes'
import Task from './checklist/Task'
import { useNextTaskSelector } from '../../common/utils/tasks'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'

type NextTaskButtonProps = {
  projectId: string,
  /** variant=`detailed` displays additional information, like the icon, in a larger display. `simple` is just an ionButton
   *
   * `detailed` does not use an IonButton and so ionbutton props are ignored
   */
  variant?: "detailed" | "simple",
} & ComponentProps<typeof IonButton>

const defaultIonButtonProps: ComponentProps<typeof IonButton> = {
  expand: "block",
}

const PROJECT_TASKS_STALETIME = Duration.fromObject({ minutes: 5 }).as('milliseconds')

const NextTaskButton: React.FC<NextTaskButtonProps> = ({ projectId, variant = "simple", ...rawButtonProps }) => {
  const buttonProps = {
    ...defaultIonButtonProps,
    ...rawButtonProps,
  }

  const weaverFlags = useWeaverFlags()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const nextStepSelector = useNextTaskSelector()

  const nextPendingTaskQuery = useListProjectTasksQuery(gqlDataSource, { id: projectId }, {
    refetchOnWindowFocus: false,
    staleTime: PROJECT_TASKS_STALETIME,
    select: (data) => nextStepSelector(data.listProjectTasks),
  })
  const nextTask = nextPendingTaskQuery.data
  const routeToTaskActioner = useRouteTo(pageConfig_TaskActioner.path)
  if (nextPendingTaskQuery.isLoading || !nextTask) return null

  const handleClick: MouseEventHandler<unknown> = (event) => {
    event.preventDefault()
    event.stopPropagation()
    routeToTaskActioner({ taskId: nextTask.id })()
  }
  if (variant === "detailed") {
    return <div className={Styles.detailedContainer}><Task task={nextTask} onAction={({ event }) => handleClick(event)} /></div>
  }

  return weaverFlags['MW-2362-long-task-name-ellipsis']
    ? <IonButton {...buttonProps} onClick={handleClick}>
      <span className={Styles.ellipsisSupport}>{nextPendingTaskQuery.data.title}</span>
    </IonButton>
    : <IonButton {...buttonProps} onClick={handleClick}>{nextPendingTaskQuery.data.title}</IonButton>

}

export default NextTaskButton
