import React from 'react'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { TeamType } from "../../graphql/generated"
import MyArchitectProfilePage from './MyArchitectProfilePage'
import MyContractorProfilePage from './MyContractorProfilePage'
import MyHomeownerProfilePage from './MyHomeownerProfilePage'
import LoadingSpinnerPage from '../../common/components/LoadingSpinner/LoadingSpinnerPage'
import MyWeaverProfilePage from './MyWeaverProfilePage'

type TeamProfileComponents = Record<TeamType, React.FC>

const teamProfileComponents: TeamProfileComponents = {
  ARCHITECT: MyArchitectProfilePage,
  CONTRACTOR: MyContractorProfilePage,
  HOMEOWNER: MyHomeownerProfilePage,
  WEAVER: MyWeaverProfilePage,
  PARTNER: () => <div>Partner Profiles are not supported</div>,
}

const DisplayMyProfile: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()
  console.debug(`[DisplayMyProfile] Render: `, { myTeam })

  if (!myTeam) return <LoadingSpinnerPage name='displayMyProfile' />

  // Create the appropriate Profile, e.g. <MyContractorProfile />
  const DisplayProfileComponent = teamProfileComponents[myTeam.type]
  return <DisplayProfileComponent />
}

export default DisplayMyProfile
