import React from 'react'
import { IonItem } from '@ionic/react'
import { DateTime } from 'luxon'
import GoogleMapFromWeaverAddress from '../../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import { GetArchitectProfileQuery } from '../../../graphql/generated'
import { ArchitectTimelineCard } from '../MyArchitectProfilePage/ArchitectTimelineCard'

import Styles from '../MyArchitectProfilePage/ArchitectProfileDetailsCard.module.scss'

type PublicArchitectProfileDetailsCardProps = {
  architectProfile: NonNullable<GetArchitectProfileQuery['getArchitectProfile']>,
}

export const PublicArchitectProfileDetailsCard:React.FC <PublicArchitectProfileDetailsCardProps> = ({ architectProfile }) => {

  return (
    <section className={Styles.companyInfoContainer}>

      <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none'>
        <h3 className={Styles.companyTradingName}>{architectProfile.companyTradingAs}</h3>
      </IonItem>

      <p className={Styles.companyNumber}>Company number: {architectProfile.companyNumber}</p>
      {architectProfile?.companyIncorporationDate && <p>Incorporated in {DateTime.fromISO(architectProfile.companyIncorporationDate).toFormat("MMMM yyyy")}</p>}
      <p className={Styles.architectTeamType}>Architect</p>

      <h5 className={Styles.registeredOfficeTitle}>Registered office:</h5>
      <p className={Styles.registeredOfficeTitleAddress}>{formatAddressToSingleLine(architectProfile.companyAddress) ?? <em>No Address</em>}</p>

      <section className={Styles.mapSection}>
        {architectProfile.companyAddress && <GoogleMapFromWeaverAddress isExact={true} address={architectProfile.companyAddress}/>}
      </section>

    </section>
  )
}
