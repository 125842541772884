import React, { useCallback } from "react"
import { Duration } from "luxon"
import { UseQueryResult } from '@tanstack/react-query'
import { useGraphQLDataSource } from "../../../api/graphql"
import { ScreenRenderProps, useScreensWithProps } from "../../../common/hooks/useScreens"
import { GetActionableTaskQuery, TaskActionableType, useGetActionableTaskQuery } from "../../../graphql/generated"
import { useParamsFromPageConfig } from "../../../routesProvider"
import ErrorBlockContent from "../../../common/components/ErrorBlock/ErrorBlockContent"
import LoadingSpinnerPage from "../../../common/components/LoadingSpinner/LoadingSpinnerPage"
import ErrorBlockPage from "../../../common/components/ErrorBlock/ErrorBlockPage"
import WeaverIonPage from "../../../common/components/WeaverIonWrappers/WeaverIonPage"
import LoadingSpinnerContent from "../../../common/components/LoadingSpinner/LoadingSpinnerContent"
import ScreenGenericManual from "./ScreenGenericManual"
import ScreenDownloadDocuments from "./ScreenDownloadDocuments"
import ScreenOpenProject from "./ScreenOpenProject"
import ScreenUnhandled from "./ScreenUnhandled"
import ScreenOpenMemberChatroom from "./ScreenOpenMemberChatroom"
import ScreenOpenContractorProfile from "./ScreenOpenContractorProfile"
import ScreenInviteArchitect from "./ScreenInviteArchitect"
import ScreenInviteHomeowner from "./ScreenInviteHomeowner"
import ScreenUploadDocuments from "./ScreenUploadDocuments"
import ScreenUseBuildPartner from "./ScreenUseBuildPartner"

type TaskActionerScreens = TaskActionableType | "Error"

type ScreenProps = {
  getActionableTaskQuery: UseQueryResult<GetActionableTaskQuery, unknown>,
}
export type ScreenComponentProps = ScreenRenderProps<TaskActionerScreens> & ScreenProps

const TaskActionerPage: React.FC = () => {
  const { taskId } = useParamsFromPageConfig<{ taskId?: string }>()

  const datasource = useGraphQLDataSource({ api: 'core' })
  const getActionableTaskQuery = useGetActionableTaskQuery(
    datasource,
    { taskId: taskId ?? '' },
    {
      enabled: !!taskId,
      staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
    },
  )
  const task = getActionableTaskQuery.data?.getTask

  const [ Screen, activeScreen ] = useScreensWithProps<TaskActionerScreens, ScreenProps>({
    init: task?.actionableType ?? "Error",
    resetWhenValueChanges: task?.id,
    isWaiting: task === undefined,
    screenProps: {
      getActionableTaskQuery,
    },
    screens: {
      GenericManual: { render: ScreenGenericManual },
      DownloadDocuments: { render: ScreenDownloadDocuments },
      UploadDocuments: { render: ScreenUploadDocuments },
      OpenProject: { render: ScreenOpenProject },
      OpenMemberChatroom: { render: ScreenOpenMemberChatroom },
      OpenContractorProfile: { render: ScreenOpenContractorProfile },
      InviteArchitect: { render: ScreenInviteArchitect },
      InviteHomeowner: { render: ScreenInviteHomeowner },
      UseBuildPartner: { render: ScreenUseBuildPartner },
      Contracting: { render: ScreenUnhandled },
      Shortlisting: { render: ScreenUnhandled },
      Tendering: { render: ScreenUnhandled },
      Hiring: { render: ScreenUnhandled },
      Stage1: { render: ScreenUnhandled },
      Stage2: { render: ScreenUnhandled },
      Stage3: { render: ScreenUnhandled },
      Stage4: { render: ScreenUnhandled },
      Stage5: { render: ScreenUnhandled },
      Stage6: { render: ScreenUnhandled },
      Error: { render: useCallback(() => <ErrorBlockContent name='TaskActionerPage.Error'><p>There was an error while loading the screen!</p></ErrorBlockContent>, []) },
    },
  })

  if (getActionableTaskQuery.isLoading) {
    return <LoadingSpinnerPage name="TaskActionerPage"/>
  }

  if (getActionableTaskQuery.error || !getActionableTaskQuery.data) {
    return <ErrorBlockPage name='TaskActionerPage' onRefresh={getActionableTaskQuery.refetch}><p>There was an error while loading the task.</p></ErrorBlockPage>
  }

  return (
    <WeaverIonPage id={`TaskActionerPage.${activeScreen}`} key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen ?? <LoadingSpinnerContent name="TaskActionerPage.UndefinedScreen"/>}
    </WeaverIonPage>
  )
}

export default TaskActionerPage
