import { useFlags } from "launchdarkly-react-client-sdk"
import { useMemo } from "react"
import { z } from "zod"
import { objectKeys } from "../../../common/utils/objects"

/**
 * The schema for the LaunchDarkly flags that might come back.
 */
export const zWeaverFlags = z.object({
  'miscellaneous-menu': z.object({
    enabled: z.boolean(),
  }),
  'minimum-individual-profile': z.object({
    enabled: z.boolean(),
    disableAspect: z.object({
      'require-phone-number': z.boolean().optional(),
    }).optional(),
  }),
  'contractor-lead-acceptor': z.object({
    enabled: z.boolean(),
  }),
  'tasks': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      showProjectList: z.boolean(),
      showChatNextAction: z.boolean(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'contractor-profile-work-history-references': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      buttons: z.object({
        yes: z.object({
          text: z.string(),
          link: z.string().optional(), // Can include the following tokens: ${USER_EMAIL} ${REFERENCE_ID}
          email: z.string().optional(),
        }),
        no: z.object({
          text: z.string(),
          link: z.string().optional(), // Can include the following tokens: ${USER_EMAIL} ${REFERENCE_ID}
          email: z.string().optional(),
        }),
      }),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'contractor-upload-project-photos': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      recommendedPhotosRange: z.string(),
      photosLimit: z.number(),
      confirmationDescription: z.string(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'tab-nav-bar': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'task-templates': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      createProject: z.array(z.string()).optional(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  // Flag Space For ...
  // Tom
  'FAQ': z.boolean(),
  'MW-2379-google-zoom':z.boolean(),
  'MW-2410-copy-changes-march':z.boolean(),
  'MW-2387-fomo-lead-acceptance':z.boolean(),
  'MW-2443-QoL1-ui-updates':z.boolean(),

  // Chris
  'MW-2144-integrate-video-chat': z.boolean(),
  'MW-2328-suspension-guard':z.boolean(),
  'MW-2359-full-seats-ui': z.boolean(),
  'MW-2362-long-task-name-ellipsis': z.boolean(),
  'MW-2397-feedback-link-on-help-page': z.boolean(),
  'MW-2395-lightbox-for-work-history-photos': z.boolean(),
  'MW-2418-link-to-the-companies-house-profile': z.boolean(),
  'MW-2417-work-history-archived-event-should-fire-from-the-contractors-my-profile': z.boolean(),
  'MW-2416-cursor-pointer-on-hover-over-badges': z.boolean(),
  'MW-2413-hide-unnecessary-fields-from-project-history-address-capture': z.boolean(),
  'MW-2420-margin-between-photos-on-work-history': z.boolean(),
  'MW-2421-menu-item-on-team-bar': z.boolean(),
  'MW-2436-editable-ui-on-invite-form-input-fields': z.boolean(),
  'MW-2430-improve-ui-on-upload-wh-photos': z.boolean(),
  'MW-2439-show-spinner-only-when-search-is-filled-in': z.boolean(),
  'MW-2483-updated-brand-illustrations': z.boolean(),
  'MW-2475-flip-group-chat-with-private-chat-for-contractor': z.boolean(),
  'MW-2482-hide-address-from-homeowner-profile': z.boolean(),
  'MW-2505-contractor-profile-clarity-improvements': z.boolean(),
  'MW-2504-alert-on-unavailable-group-chat': z.boolean(),
  'MW-2512-project-homepage-cta-buttons': z.boolean(),

  // Dan
  'MW-2419-tag-references-as-verified': z.boolean(),

  // Dermot
  'MW-2386-remove-tender-return-date': z.boolean(),
  'MW-2360-block-contractor-accepting-lead': z.boolean(),
  'MW-2375-award-badge-on-init': z.boolean(),
  'MW-2462-remove-onboarding-pages': z.boolean(),
  'MW-2468-onboarding-improvements': z.boolean(),
  'MW-2481-architect-profile-ui-upgrade': z.boolean(),
  "MW-2450-upload-document-section-all-clickable": z.boolean(),
  'MW-2493-architect-menu-project-page': z.boolean(),
  'cost-calculator-architect-menu': z.boolean(),
  'vet-my-builder-architect-menu': z.boolean(),
  'MW-2489-remove-builder-from-project-v1': z.boolean(),
  'MW-2498-document-uploads-flow-improvement': z.boolean(),

  // Diego

  // Rowan
  'MW-2388-tasks-qol1-part2': z.boolean(),
  'MW-2415-lead-cache-busting': z.boolean(),
  'MW-2422-tasks-qol1-part3': z.boolean(),
})

export type WeaverFlags = z.infer<typeof zWeaverFlags>

export const defaultWeaverFlags: WeaverFlags = {
  'miscellaneous-menu': {
    enabled: false,
  },
  'minimum-individual-profile': {
    enabled: false,
  },
  'contractor-lead-acceptor': {
    enabled: false,
  },
  'tasks': {
    enabled: false,
  },
  'contractor-profile-work-history-references': {
    enabled: true,
    buttons: {
      yes: {
        text: 'Complete reference form',
        link: 'https://app.weaver.build/${USER_EMAIL}/${REFERENCE_ID}',
      },
      no: {
        text: "I don't want to provide a reference",
        email: 'support@weaver.build',
      },
    },
  },
  'contractor-upload-project-photos': {
    enabled: false,
  },
  'tab-nav-bar': {
    enabled: false,
  },
  'task-templates': {
    enabled: false,
  },
  // Flag Space For ...
  // Tom
  'FAQ': false,
  'MW-2379-google-zoom': false,
  'MW-2410-copy-changes-march' : false,
  'MW-2387-fomo-lead-acceptance': false,
  'MW-2443-QoL1-ui-updates': false,

  // Chris
  'MW-2144-integrate-video-chat': false,
  'MW-2328-suspension-guard': false,
  'MW-2359-full-seats-ui': false,
  'MW-2362-long-task-name-ellipsis': false,
  'MW-2397-feedback-link-on-help-page': false,
  'MW-2395-lightbox-for-work-history-photos': false,
  'MW-2418-link-to-the-companies-house-profile': false,
  'MW-2417-work-history-archived-event-should-fire-from-the-contractors-my-profile':false,
  'MW-2416-cursor-pointer-on-hover-over-badges': false,
  'MW-2413-hide-unnecessary-fields-from-project-history-address-capture': false,
  'MW-2420-margin-between-photos-on-work-history': false,
  'MW-2421-menu-item-on-team-bar': false,
  'MW-2436-editable-ui-on-invite-form-input-fields': false,
  'MW-2430-improve-ui-on-upload-wh-photos': false,
  'MW-2439-show-spinner-only-when-search-is-filled-in': false,
  'MW-2483-updated-brand-illustrations': false,
  'MW-2475-flip-group-chat-with-private-chat-for-contractor': false,
  'MW-2482-hide-address-from-homeowner-profile': false,
  'MW-2505-contractor-profile-clarity-improvements': false,
  'MW-2504-alert-on-unavailable-group-chat': false,
  'MW-2512-project-homepage-cta-buttons': false,

  // Dan
  'MW-2419-tag-references-as-verified': false,

  // Dermot
  'MW-2386-remove-tender-return-date': false,
  'MW-2360-block-contractor-accepting-lead': false,
  'MW-2375-award-badge-on-init': false,
  'MW-2462-remove-onboarding-pages': false,
  'MW-2468-onboarding-improvements': false,
  'MW-2481-architect-profile-ui-upgrade': false,
  'MW-2450-upload-document-section-all-clickable': false,
  'MW-2493-architect-menu-project-page': false,
  'cost-calculator-architect-menu': false,
  'vet-my-builder-architect-menu': false,
  'MW-2489-remove-builder-from-project-v1': false,
  'MW-2498-document-uploads-flow-improvement': false,

  // Diego
  // Rowan
  'MW-2388-tasks-qol1-part2': false,
  'MW-2415-lead-cache-busting': false,
  'MW-2422-tasks-qol1-part3': false,
}

/**
   * A function generator which takes a `zod shape` of an object,
   * along with a `candidate object` that *may* represent that shape,
   * and a `default object` *fully* represents that shape.
   *
   * It then produces an `Array.reduce()` function which iterates over each key in the `zod shape`.
   * If that key's value within the `candidate object` passes that key's zod shape, then it is taken.
   * Otherwise, that key's value from the `default object` is used.
   *
   * The primary use for this is to take the `LaunchDarkly` set of flags and to default only the flags which don't pass validation.
   *
   * @returns `Partial<X>` which should actually be a full `X` (where `X` matches the defaultObject type)
   */
export const defaultInvalidCandidateObjectValues = <T extends z.AnyZodObject, X = z.infer<T>>(zodShape: T, candidateObject: Partial<X>, defaultObject: X) =>
  (acc: Partial<X>, key: keyof X) => {
    const parseResult = zodShape.shape[key].safeParse(candidateObject[key])
    if (parseResult.success) {
      return { ...acc, [key]: candidateObject[key] }
    } else {
      console.error(`[useWeaverFlags.defaultInvalidCandidateObjectValues] Unable to parse the key '${String(key)}' as flags. Defaulting it.`, { error: parseResult.error, candidate: candidateObject[key], default: defaultObject[key] })
      return { ...acc, [key]: defaultObject[key] }
    }
  }

/**
 * Returns a strongly typed set of LaunchDarkly flags, or throws an error.
 * WARNING: Changes to LaunchDarkly config will change this behaviour!
 */
export const useWeaverFlags = (): WeaverFlags => {
  const flags = useFlags()

  const weaverFlags: WeaverFlags = useMemo(() => {
    console.debug('[useWeaverFlags] Processing the LaunchDarkly flags', flags)

    // Cold start support - Default an empty object or undefined state
    if (!flags || Object.keys(flags).length === 0) {
      console.log('[useWeaverFlags] Cold start detected. Using defaultWeaverFlags for now.', flags)
      return defaultWeaverFlags
    }

    // Apply the reducer to the LaunchDarkly flags, evaluating each flag independently
    // It should return a full WeaverFlags object (as we iterate over every potential key and default), but Typescript can't be sure
    const weaverFlagReducer = defaultInvalidCandidateObjectValues(zWeaverFlags, flags, defaultWeaverFlags)
    const defaultedFlags: Partial<WeaverFlags> = objectKeys(zWeaverFlags.shape).reduce(weaverFlagReducer, {})

    // We rerun the parse across the whole object - this really should not fail!
    const parsedFlags = zWeaverFlags.safeParse(defaultedFlags)
    if (!parsedFlags.success) {
      console.error('[useWeaverFlags] CRITICAL! Unable to parse the whole flagset. This should never happen!', parsedFlags.error)
      return defaultWeaverFlags
    }

    return parsedFlags.data
  }, [ flags ])

  console.debug('[useWeaverFlags] Successfully loaded WeaverFlags: ', weaverFlags)
  return {
    ...weaverFlags,
    // Add local flag overrides here
  }
}
