import React from 'react'
import { IonItem, IonText } from '@ionic/react'
import TeamMembers from '../TeamMembers'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetArchitectProfileQuery } from '../../../graphql/generated'
import Styles from '../Profile.module.scss'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { PublicArchitectProfileDetailsCard } from './PublicArchitectProfileDetailsCard'
import { ArchitectTimelineCard } from '../MyArchitectProfilePage/ArchitectTimelineCard'

type PublicArchitectProfileProps = {
  id: string,
}

const PublicArchitectProfile: React.FC<PublicArchitectProfileProps> = ({ id }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data , error, isFetching, refetch } = useGetArchitectProfileQuery(gqlDataSource, { architectTeamId: id })

  const weaverFlags = useWeaverFlags()

  if (isFetching) {
    return <LoadingSpinner name="PublicArchitectProfile" />
  }

  if (error) {
    console.error(`[PublicArchitectProfile] Failed to load Architect Profile: `, { id, data, error })
    return <ErrorBlock name='PublicArchitectProfile.failed' onRefresh={refetch} />

  }

  if (data?.getArchitectProfile == null) {
    console.warn(`[PublicArchitectProfile] Architect Profile is missing: `, { id, data, error })
    return <ErrorBlock name='PublicArchitectProfile.missing'><p>Unable to find the Architect Profile.</p></ErrorBlock>
  }

  const profile = data.getArchitectProfile

  return <>
    {weaverFlags['MW-2481-architect-profile-ui-upgrade']
      ? <>
        <PublicArchitectProfileDetailsCard architectProfile={profile}/>
        <ArchitectTimelineCard architectProfile={profile} />
      </>
      : <>
        <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
          <h3>{profile.companyTradingAs}</h3>
        </IonItem>
        <IonText className={Styles.lightGrey}>
          <p> Architect </p>
        </IonText>
        <IonText className={Styles.lightGrey}>
          <p>{formatAddressToSingleLine(profile.companyAddress) ?? <em>No Address</em>}</p>
        </IonText>
      </>
    }

    {!weaverFlags['MW-2481-architect-profile-ui-upgrade'] && <TeamMembers teamId={id} />}
  </>
}

export default PublicArchitectProfile
