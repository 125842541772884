import React from "react"
import LoadingSpinnerContent from "../../../../common/components/LoadingSpinner/LoadingSpinnerContent"
import { ScreenComponentProps } from "../TaskActionerPage"
import Styles from '../../TaskActionerPage/TaskActionerPage.module.scss'
import GlobalHeaderStyles from '../../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { IonText, IonFooter, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import GlobalHeader from "../../../../common/components/GlobalHeader"
import WeaverIonContent from "../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonHeader from "../../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import LogoImage from "./logo.png"
import CoverImage from "./cover.jpg"
import { useMarkTaskCompleteMutation, useMarkTaskSkippedMutation } from "../commonActions"

const ScreenUseBuildPartner: React.FC<ScreenComponentProps> = (props) => {
  const { getActionableTaskQuery } = props
  const markTaskCompleteMutation = useMarkTaskCompleteMutation(getActionableTaskQuery)
  const markTaskSkippedMutation = useMarkTaskSkippedMutation(getActionableTaskQuery)
  if (!getActionableTaskQuery.data) return <LoadingSpinnerContent name="ScreenUseBuildPartner" />

  const handleClick = async () => {
    window.open('https://my.buildpartner.com/#/sign-up', '_blank')
    await markTaskCompleteMutation()
  }

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={"Create cost plan"}/>
    </WeaverIonHeader>
    <WeaverIonContent>
      <div className="ion-padding-horizontal">
        <h2 className={`${Styles.title} ion-margin-top`}>Create quote in a fraction of the usual time using BuildPartner</h2>
        <IonText className={Styles.ionText}>Weaver recommended partner</IonText>
      </div>
      <IonCard>
        <IonCardHeader>
          <img src={LogoImage} alt="BuildPartner Logo" />
          <IonCardTitle>Save time, money and stress!</IonCardTitle>
          <IonText color={"dark"}>Select from templates and enter dimensions to create a quick, accurate cost plan.</IonText>
        </IonCardHeader>
        <IonCardContent>
          <img src={CoverImage} alt="BuildPartner Cover Image" />
        </IonCardContent>
      </IonCard>
    </WeaverIonContent>
    <IonFooter className='ion-padding-horizontal ion-no-border'>
      <IonButton expand="block" onClick={handleClick}>Start Free Trial</IonButton>
      <IonButton className={Styles.ionOutlineButton} expand="block" fill="outline" onClick={markTaskCompleteMutation}>Mark Task As Complete</IonButton>
      <IonButton expand="block" fill="clear" onClick={markTaskSkippedMutation}>Skip, Not Interested</IonButton>
    </IonFooter>
  </>
}

export default ScreenUseBuildPartner
