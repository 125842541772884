import { z } from "zod"
import { Currency } from "./generated"

/**
 * These are currently manually created to map onto the `generated.ts` types
 */

export const zMoney = z.object({
  currency: z.nativeEnum(Currency),
  amountInPence: z.string(),
})

/** HACK: Nullish should be enough but this was broken when updated Zod 3.14 to 3.21 */
export const zWeaverAddressInput = z.object({
  careOf: z.string().optional().nullish(),
  poBox: z.string().optional().nullish(),
  addressLine1: z.string().optional().nullish(),
  addressLine2: z.string().optional().nullish(),
  postTown: z.string().optional().nullish(),
  county: z.string().optional().nullish(),
  country: z.string().optional().nullish(),
  /*
   The provided regular expression guarantees that the postal code meets the necessary validation criteria.
   We made a discovery during testing that our regex did not initially support post codes starting with "NPT."
   To rectify this, we included an "OR |" condition specifically for Newport areas.
   To ensure the validity of our regex, we ran a node script against a large number of UK post codes.
  */
  postCode: z.string().min(2, 'Postcode required').refine((value) => /^(([A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]?)|NPT) [0-9][A-Za-z]{2}$/.test(value), 'Postcode is not valid'),
})
