import React, { useEffect, useState } from 'react'
import { IonChip, IonIcon, IonItem, IonLabel, IonPopover, IonText, useIonRouter } from '@ionic/react'
import MemberAvatar from './MemberAvatar'
import Styles from "./MemberRow.module.scss"
import { useGraphQLDataSource } from '../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { PublicProfileModal } from '../../../domains/profile/PublicProfileModal'
import { ShowProjectQuery, ChatRoom, useGetChatRoomForProjectTeamsQuery, TeamType, WeaverAddress, BudgetRange } from '../../../graphql/generated'
import { capitalizeFirstCharacter } from '../../utils/string'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { pageConfig_Profile_Public, useRouteTo } from '../../../routes'
import { ellipsisHorizontalOutline } from 'ionicons/icons'
import { isCurrentUserProjectOwner } from '../../../domains/projects/common'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useAuthContext } from '../../../api/providers/AuthProvider'
import { formatAddressToSingleLine } from '../../utils/addresses'

type Project = ShowProjectQuery["getProject"]
type ProjectMember = NonNullable<Project["members"]>[number]
type ProjectMemberTeam = ProjectMember["team"]

type MemberRowProps = {
  /** All members on the project */
  members?: ProjectMember[],
  /* The team that is a member of the Project or Chat. */
  team: ProjectMemberTeam,
  /** The id of the project the Chat Room is associated with */
  projectId?: string | null | undefined,
  projectAddress?: WeaverAddress | null,
  budgetRange?: BudgetRange,
  /* This function creates the chat in case where the chat doesn't exist yet. */
  createChatRoom: (team1: ProjectMemberTeam, team2: ProjectMemberTeam) => Promise<Pick<ChatRoom, 'id' >>,
  /* The action called when the chat button is clicked. Thus, consumers of this component are allowed to customise what happens then (e.g: running a validation) */
  onChatBubbleClickedAction: (selectedTeam: ProjectMemberTeam, goToChatRoomCreateIfNecessary: () => void) => void,
  /* This is used to determine if we are visiting a public profile from chat room member page. */
  isChatRoomMemberPage?: boolean,
}

/**
 * Represents a Team that is a member of a Chat or a Project. It displays basic information about the Team as well a button to start or open a chat between the current user
 * and the selected team. The consumers of this component are provided with a way to overide the action of this button.
 */
export const MemberRow: React.FC<MemberRowProps> = ({ members, team, projectAddress, projectId, budgetRange, isChatRoomMemberPage, createChatRoom, onChatBubbleClickedAction }) => {
  const auth = useAuthContext()
  const weaverFlags = useWeaverFlags()
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const [ showModal, setShowModal ] = useState<boolean>(false)
  if (!myTeam) throw new Error("Can't render this component without an active team")

  const [ showPopover, setShowPopover ] = useState<{open: boolean, event: Event | undefined}>({
    open: false,
    event: undefined,
  })

  const ionRouter = useIonRouter()
  const goToMemberProfile = useRouteTo(pageConfig_Profile_Public.path)
  const getChatRoomForProjectTeamsQuery = useGetChatRoomForProjectTeamsQuery(gqlDataSource, { teamIds: [ myTeam.id,  team.id ], projectId }, { refetchOnWindowFocus: true })
  const contractorRejectClickedEvent = useAnalyticsEvent('Contractor_Reject_Clicked')
  const publicProfileViewed = useAnalyticsEvent('Project_PublicProfile_Viewed')
  const chatRoomPublicProfileViewed = useAnalyticsEvent('Chat_PublicProfile_Viewed')

  const isProjectOwner = isCurrentUserProjectOwner(myTeam, members)

  useEffect(() => {
    setShowModal(false)
  }, [ ionRouter.routeInfo.pathname ])

  const navigateToChatPage = (chatRoomId: string) => {
    ionRouter.push(`chats/${chatRoomId}`)
  }

  const navigateToProfile = async () => {
    setShowPopover({ open: false, event: undefined })
    const existingChatRoomId = getChatRoomForProjectTeamsQuery?.data?.getChatRoomForProjectTeams?.id

    if (isChatRoomMemberPage) {
      await chatRoomPublicProfileViewed({
        chatRoomId: existingChatRoomId,
        teamId: team.id,
        teamType: team.type,
        teamName: team.name ?? '',
        viewedByTeamId: myTeam.id,
        viewedByTeamType: myTeam.type,
      })
    } else {
      await publicProfileViewed({
        projectId: projectId ?? '',
        teamId: team.id,
        teamType: team.type,
        teamName: team.name ?? '',
        viewedByTeamId: myTeam.id,
        viewedByTeamType: myTeam.type,
      })
    }

    goToMemberProfile({ teamType: team.type, teamId: team.id })()
  }

  const goToChatRoomCreateIfNecessary = async () => {
    if (!getChatRoomForProjectTeamsQuery.data) return

    const existingChatRoomId = getChatRoomForProjectTeamsQuery?.data.getChatRoomForProjectTeams?.id
    if (existingChatRoomId){
      navigateToChatPage(existingChatRoomId)
    } else {
      const result = await createChatRoom(myTeam, team)
      navigateToChatPage(result.id)
    }
  }

  const handleClick = async () => {

    const existingChatRoomId = getChatRoomForProjectTeamsQuery?.data?.getChatRoomForProjectTeams?.id

    if (isChatRoomMemberPage) {
      await chatRoomPublicProfileViewed({
        chatRoomId: existingChatRoomId,
        teamId: team.id,
        teamType: team.type,
        teamName: team.name ?? '',
        viewedByTeamId: myTeam.id,
        viewedByTeamType: myTeam.type,
      })

    } else {
      await publicProfileViewed({
        projectId: projectId ?? '',
        teamId: team.id,
        teamType: team.type,
        teamName: team.name ?? '',
        viewedByTeamId: myTeam.id,
        viewedByTeamType: myTeam.type,
      })
    }

    weaverFlags['tab-nav-bar'].enabled
      ? goToMemberProfile({ teamType: team.type, teamId: team.id })()
      : setShowModal(true)
  }

  const handleRejectBuilder = () => {
    //check optional fields are present
    if (!projectId || !team.id || !team.name || !myTeam.name) return console.error('[MemberRow]: ProjectId, TeamId, or TeamName missing')

    const REMOVE_BUILDER_TYPEFORM_URL = 'https://weavertech.typeform.com/to/C0lokOim'
    const url = new URL(REMOVE_BUILDER_TYPEFORM_URL)
    url.searchParams.set("team_name", `${myTeam.name}`)
    url.searchParams.set("team_id", `${myTeam.id}`)
    url.searchParams.set("rejected_contractor_name", `${team.name}`)
    url.searchParams.set("team_type", `${myTeam.type}`)
    url.searchParams.set("individual_id", `${myTeam.id}`)
    url.searchParams.set("email", `${auth.userData?.email}`)
    url.searchParams.set("project_id", `${projectId}`)
    url.searchParams.set("budget_range", `${budgetRange}`)
    url.searchParams.set("address", `${formatAddressToSingleLine(projectAddress)}`)
    url.searchParams.set("rejected_team_type", `${team.type}`)
    url.searchParams.set('rejected_team_id', `${team.id}`)
    url.searchParams.set("rejected_team_type", `${team.type}`)

    contractorRejectClickedEvent({
      projectId,
      contractorTeamId: team.id,
      contractorTeamName: team.name,
      projectOwnerTeamId: myTeam.id,
      projectOwnerTeamName: myTeam.name,
      projectOwnerTeamType: myTeam.type,
    })
    setShowPopover({ open: false, event: undefined })
    window.open(url, '_blank')
  }

  return (
    <>
      <IonItem key={team.id} className={Styles.memberItem} lines="full">
        <MemberAvatar slot="start" title={team.name} />
        <div onClick={handleClick}>
          <IonLabel className={Styles.memberLabel} >
            <p>{team.name}</p>
          </IonLabel>
          <IonLabel>
            <p>{capitalizeFirstCharacter(team.type)}</p>
          </IonLabel>
        </div>
        <IonChip className={Styles.chatChipButton} outline slot="end" hidden={myTeam.id === team.id} disabled={getChatRoomForProjectTeamsQuery.isLoading} onClick={event => {
          event.preventDefault()
          onChatBubbleClickedAction(team, goToChatRoomCreateIfNecessary)
          event.stopPropagation()
        }}>
          <span className={Styles.chipText}>Message</span>
        </IonChip>
        { weaverFlags['MW-2421-menu-item-on-team-bar'] &&
          <IonIcon
            className={Styles.menuIcon}
            color='primary' slot='end'
            hidden={myTeam.id === team.id}
            icon={ellipsisHorizontalOutline}
            onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}/>
        }
        <IonPopover
          isOpen={showPopover.open}
          side="bottom"
          alignment='end'
          event={showPopover.event}
          showBackdrop={false}
          onDidDismiss={() => setShowPopover({ open: false, event: undefined })}
        >
          <div className={`${Styles.popOverItem} ion-padding`} onClick={navigateToProfile}>
            View profile
          </div>

          { (team.type === TeamType.Contractor && isProjectOwner && weaverFlags['MW-2489-remove-builder-from-project-v1']) &&
          <div className={`${Styles.popOverItem} ion-padding`} onClick={handleRejectBuilder}>
            <IonText color='danger'>Reject builder</IonText>
          </div>
          }

        </IonPopover>
      </IonItem>
      <PublicProfileModal
        team={team}
        breakpoints={[ 0, 0.5, 0.75, 0.95 ]}
        initialBreakpoint={0.95}
        isOpen={showModal}
        canDismiss={true}
        onDidDismiss={() => setShowModal(false)}
      />
    </>
  )
}
