import { IonModal } from '@ionic/react'
import React, { ComponentProps } from 'react'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import { Team, TeamType } from '../../graphql/generated'
import PublicArchitectProfileModal from './PublicArchitectProfile/PublicArchitectProfileModal'
import PublicContractorProfileModal from './PublicContractorProfile/PublicContractorProfileModal'
import PublicHomeownerProfileModal from './PublicHomeownerProfile/PublicHomeownerProfileModal'
import PublicWeaverProfileModal from './PublicWeaverProfile/PublicWeaverProfileModal'

export type TeamPublicProfileModalComponentProps = {
  team: Pick<Team, "id" | "name" | "type">,
} & ComponentProps<typeof IonModal>

const teamProfileModalComponents: Record<TeamType, React.FC<TeamPublicProfileModalComponentProps>> = {
  ARCHITECT: PublicArchitectProfileModal,
  CONTRACTOR: PublicContractorProfileModal,
  HOMEOWNER: PublicHomeownerProfileModal,
  WEAVER: PublicWeaverProfileModal,
  PARTNER: () => <div>Partner Profiles are not supported</div>,
}

export const PublicProfileModal: React.FC<TeamPublicProfileModalComponentProps> = ({ ...teamProfileModalProps }) => {
  console.debug(`[PublicProfileModal] Render: `, { teamType: teamProfileModalProps.team.type, teamId: teamProfileModalProps.team.id })

  if (!teamProfileModalProps.team) return <LoadingSpinner name='PublicProfileModal' />

  const PublicProfileModalComponent = teamProfileModalComponents[teamProfileModalProps.team.type]
  return <PublicProfileModalComponent {...teamProfileModalProps}  />
}
