import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon, isPlatform } from '@ionic/react'
import React, { useState } from 'react'
import GlobalHeader from '../../common/components/GlobalHeader'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useGraphQLDataSource } from '../../api/graphql'
import { ContentDisposition, UploadedFileStatus, TeamType, useGetProjectDocumentsQuery, useListProjectTasksQuery, TaskActionableType, useSetTaskStatusMutation, TaskStatus, useShowProjectQuery } from '../../graphql/generated'
import { pageConfig_UploadProjectDocuments, useRouteTo } from '../../routes'
import { useAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'
import ErrorBlockPage from '../../common/components/ErrorBlock/ErrorBlockPage'
import LoadingSpinnerPage from '../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { asyncForEach } from '../../common/utils'
import { downloadUploadedFile } from '../../common/utils/files'
import { alwaysArray } from "../../common/utils"
import { cloudDownloadOutline, cloudUploadOutline } from 'ionicons/icons'
import ProjectDocumentRow from './ProjectDocumentRow'
import { ProjectDocument } from './ProjectDocumentTypes'
import { useQueryClient } from '@tanstack/react-query'
import { useParamsFromPageConfig } from '../../routesProvider'
import { useSearchState } from '../../common/hooks/pages'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useAsyncQuery } from '../../common/hooks/query'
import { Duration } from 'luxon'
import { unpackTasksWithDepth, useActionableTaskFilter } from '../../common/utils/tasks'
import { useAchievementToast } from '../../api/providers/AchievementToastProvider/AchievementToastProvider'
import { z } from 'zod'

type DocumentsToDownload = {
  [documentId: string]: boolean,
}

const zSearchSchema = z.object({
  /**
   * HACK: ignoreScope allows a ops (weaver team) member to ignore scoping permissions
   * this will be `"true"` when an ops member is requesting access to a project they are not part of
   */
  ignoreScope: z.coerce.boolean().optional(),
})

const UPLOAD_BUTTON_MESSAGE = "You don't have any uploaded documents, add documents by tapping the upload button."

const ProjectDocumentsPage: React.FC = () => {
  const gqlDataSource = useGraphQLDataSource({ api: "core" })
  const queryClient = useQueryClient()
  const { searchData } = useSearchState({}, { schema: zSearchSchema })
  const ignoreScope = !!searchData.ignoreScope

  const {
    ["MW-2388-tasks-qol1-part2"]: tasksQuol1Part2,
  } = useWeaverFlags()

  const goToUploadDocumentsPage = useRouteTo(pageConfig_UploadProjectDocuments.path)
  const asyncQuery = useAsyncQuery({ api: "core" })
  const isActionableFilter = useActionableTaskFilter()
  const { present } = useAchievementToast()
  const setTaskStatusMutation = useSetTaskStatusMutation(gqlDataSource, { onSuccess: async () => {
    await queryClient.invalidateQueries(useListProjectTasksQuery.getKey({ id: project.id }))
  } })
  const { id } = useParamsFromPageConfig<{id: string}>()

  const getProjectDocuments = useGetProjectDocumentsQuery(gqlDataSource, {
    id,
    config: { disposition: ContentDisposition.Attachment, transformation: { width: 1000, height: 1000 } },
    ignoreScope,
  }, { refetchOnWindowFocus: false })

  const triggerProjectDocumentDownloaded = useAnalyticsEvent("Project_Document_Downloaded")
  const triggerProjectDocumentDownloadedAll = useAnalyticsEvent("Project_Document_Downloaded_All")
  const myTeam = useMyIndividualActiveTeam()
  const { ["MW-2422-tasks-qol1-part3"]: tasksQol1Part3 } = useWeaverFlags()

  const [ documentsToDownload, setDocumentsToDownload ] = useState<DocumentsToDownload>({})

  const downloadAllProjectFiles = (listOfAllDocumentFiles: ProjectDocument[]) =>
    asyncForEach(listOfAllDocumentFiles, async (document: ProjectDocument) => {
      const saveToDownloadsFolder = true
      return downloadUploadedFile(document, (hasCompleted: boolean) => {
        setDocumentsToDownload({
          ...documentsToDownload,
          [document.id]: hasCompleted,
        })
      }, saveToDownloadsFolder)
    })

  const onDownloadAllClicked = async () => {
    downloadAllProjectFiles(alwaysArray(documents))

    const tasks = await asyncQuery(useListProjectTasksQuery, { id: project.id }, { staleTime: Duration.fromObject({ minutes: 5 }).toMillis() })
    const unpackedTasks = unpackTasksWithDepth(tasks.listProjectTasks)
    const nextActionableDownloadTask = unpackedTasks.find(task => task.actionableType === TaskActionableType.DownloadDocuments && isActionableFilter(task))

    if (nextActionableDownloadTask) {
      /** This background task completion is a duplicate of useBackgroundActionTask. Replace this when MW-2422-tasks-qol1-part3 is deprecated */
      console.log("[ProjectDocumentsPage] found actionable DownloadDocuments task", nextActionableDownloadTask, "completing...")
      await setTaskStatusMutation.mutateAsync({ newStatus: TaskStatus.Completed, taskId: nextActionableDownloadTask.id })
      if (tasksQuol1Part2) {
        present({ message: nextActionableDownloadTask.title })
      }
    }

    triggerProjectDocumentDownloadedAll({
      projectId: project.id,
      downloads: documents.map((document) => {
        const { fileName, fileContentType: fileType, fileSizeInBytes } = document
        const hasRequiredFields = fileName && fileType && fileSizeInBytes
        if (!hasRequiredFields) throw new Error(`Failed to report downloadAll, document was missing required fields: ${JSON.stringify(document)}`)
        return ({
          fileName,
          fileType,
          fileSizeInBytes,
        })
      }),
    })
  }

  const navigateToUploadDocumentsPage = () => {
    goToUploadDocumentsPage({ projectId: id })()
  }

  if (getProjectDocuments.isLoading && !getProjectDocuments.data) {
    return <LoadingSpinnerPage name="ProjectDocuments"/>
  }

  if (!myTeam) {
    return <LoadingSpinnerPage name="ProjectDocuments" />
  }

  if (getProjectDocuments.error || !getProjectDocuments.data) {
    return <ErrorBlockPage name='ProjectDocumentsPage' onRefresh={getProjectDocuments.refetch} />
  }

  const canUserUploadFiles = () => myTeam.type !== TeamType.Contractor
  const includeNonArchivedDocuments = (document: ProjectDocument) => document.status !== UploadedFileStatus.Archived

  const { getProject: project } = getProjectDocuments.data

  const documents = alwaysArray(project.documents).filter(includeNonArchivedDocuments)

  const handleDownloadDocument = (document: ProjectDocument) => {
    const { fileName, fileContentType: fileType, fileSizeInBytes } = document
    const hasRequiredFields = fileName && fileType && fileSizeInBytes
    if (!hasRequiredFields) throw new Error(`Failed to report download: Document was missing required fields: ${JSON.stringify(document)}`)

    triggerProjectDocumentDownloaded({
      projectId: project.id,
      fileName,
      fileType,
      fileSizeInBytes,
    })
  }

  const handleArchiveDocument = async () => {
    await queryClient.invalidateQueries(useGetProjectDocumentsQuery.getKey({ id }))
  }

  return (
    <WeaverIonPage id='ProjectDocumentsPage'>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='Documents' pageSubtitle={tasksQol1Part3 ? project.title : undefined} />
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonCard>
          <IonCardContent>
            <IonCardTitle>Documents</IonCardTitle>
            { canUserUploadFiles() &&
            <IonButton onClick={() => navigateToUploadDocumentsPage()}><IonIcon icon={cloudUploadOutline} slot="start" />Upload</IonButton>
            }

            {( documents.length > 0 )
              ?<>
                <IonButton onClick={() => onDownloadAllClicked()} hidden={isPlatform('mobileweb')}><IonIcon icon={cloudDownloadOutline} slot="start"/>Download all</IonButton>
                {( documents.map((document) =>
                  <ProjectDocumentRow
                    key={document.id}
                    document={document}
                    isDownloadingAll={documentsToDownload.documentId}
                    onDownload={handleDownloadDocument}
                    onArchived={handleArchiveDocument}
                  />,
                ))}
              </>
              : <IonCardContent>{UPLOAD_BUTTON_MESSAGE}</IonCardContent>
            }
          </IonCardContent>
        </IonCard>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default ProjectDocumentsPage
