import React from 'react'
import { IonIcon, IonItem } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import { DateTime } from 'luxon'
import GoogleMapFromWeaverAddress from '../../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import { GetArchitectProfileQuery } from '../../../graphql/generated'

import Styles from './ArchitectProfileDetailsCard.module.scss'

type MyArchitectProfileDetailsCardProps = {
  architectProfile: NonNullable<GetArchitectProfileQuery['getArchitectProfile']>,
  goToEditMyProfile: () => void,
}

export const MyArchitectProfileDetailsCard:React.FC <MyArchitectProfileDetailsCardProps> = ({ architectProfile, goToEditMyProfile }) => {

  return <section className={Styles.companyInfoContainer}>
    <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none' onClick={goToEditMyProfile}>
      <h3 className={Styles.companyTradingName}>{architectProfile.companyTradingAs}</h3>
      <IonIcon className={Styles.editIcon} color='primary' src={createOutline} />
    </IonItem>

    <p className={Styles.companyNumber}>Company number: {architectProfile.companyNumber}</p>
    {architectProfile?.companyIncorporationDate && <p>Incorporated in {DateTime.fromISO(architectProfile.companyIncorporationDate).toFormat("MMMM yyyy")}</p>}
    <p className={Styles.architectTeamType}>Architect</p>

    <h5 className={Styles.registeredOfficeTitle}>Registered office:</h5>
    <p className={Styles.registeredOfficeTitleAddress}>{formatAddressToSingleLine(architectProfile.companyAddress) ?? <em>No Address</em>}</p>

    <section className={Styles.mapSection}>
      {architectProfile.companyAddress && <GoogleMapFromWeaverAddress isExact={true} address={architectProfile.companyAddress}/>}
    </section>

  </section>
}
