import React from 'react'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { TeamType } from "../../graphql/generated"
import EditContractorProfilePage from './EditContractorProfile'
import EditArchitectProfilePage from './EditArchitectProfile/EditArchitectProfilePage'
import NotImplementedPage from '../../common/components/NotImplementedPage'
import LoadingSpinnerPage from '../../common/components/LoadingSpinner/LoadingSpinnerPage'
import EditHomeownerProfilePage from './EditHomeownerProfile/EditHomeownerProfilePage'

type TeamProfileComponents = Record<TeamType, React.FC<{ id: string }>>

const teamProfileComponents: TeamProfileComponents = {
  ARCHITECT: EditArchitectProfilePage,
  CONTRACTOR: EditContractorProfilePage,
  HOMEOWNER: EditHomeownerProfilePage,
  WEAVER: NotImplementedPage,
  PARTNER: NotImplementedPage,
}

const EditMyProfile: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()
  console.debug(`[EditMyProfile] Render: `, { myTeam })

  if (!myTeam) return <LoadingSpinnerPage name='editMyProfile' />

  // Create the appropriate Profile, e.g. <EditContractorProfile id={myTeam.id} />
  const EditProfileComponent = teamProfileComponents[myTeam.type]
  return <EditProfileComponent id={myTeam.id} />
}

export default EditMyProfile
